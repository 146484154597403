import React, { createContext, useReducer } from "react";

export const SearchStore = createContext();

const initState = {
    activeStory: {}
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'ACTIVE_STORY':
            return { ...state, activeStory: action.payload };
        default:
            return state;
    }
}

export const SearchStoreProvider = ({ getValue, ...props }) => {
    const [state, dispatch] = useReducer(reducer, initState);
    const value = { state, dispatch };

    if (getValue) {
        getValue(value)
    }

    return (
        <SearchStore.Provider value={value}>{props.children}</SearchStore.Provider>
    )
}