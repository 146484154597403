import React, { createContext, useReducer } from "react";

export const GlobalStore = createContext();

const initState = {
    stories: [],
    isStandby: false
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'FETCH_STORIES':
            return { ...state, stories: action.payload };

        case 'EXIT_STANDBY':
            return { ...state, isStandby: false };

        case 'ENTER_STANDBY':
            return { ...state, isStandby: true };

        default:
            return state;
    }
}

export const GlobalStoreProvider = props => {
    const [state, dispatch] = useReducer(reducer, initState);
    const value = { state, dispatch };

    return (
        <GlobalStore.Provider value={value}>{props.children}</GlobalStore.Provider>
    )
}