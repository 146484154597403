import React from "react";

const Icon = ({ name }) => {
    switch (name) {
        case 'arrow-left':
            return (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 492 492">
                    <path d="M464.344 207.418l.768.168H135.888l103.496-103.724c5.068-5.064 7.848-11.924 7.848-19.124 0-7.2-2.78-14.012-7.848-19.088L223.28 49.538c-5.064-5.064-11.812-7.864-19.008-7.864-7.2 0-13.952 2.78-19.016 7.844L7.844 226.914C2.76 231.998-.02 238.77 0 245.974c-.02 7.244 2.76 14.02 7.844 19.096l177.412 177.412c5.064 5.06 11.812 7.844 19.016 7.844 7.196 0 13.944-2.788 19.008-7.844l16.104-16.112c5.068-5.056 7.848-11.808 7.848-19.008 0-7.196-2.78-13.592-7.848-18.652L134.72 284.406h329.992c14.828 0 27.288-12.78 27.288-27.6v-22.788c0-14.82-12.828-26.6-27.656-26.6z"></path>
                </svg>
            )

        case 'chevron-left':
            return (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
                    <path d="M207.093 30.187L176.907 0l-128 128 128 128 30.186-30.187L109.28 128z"></path>
                </svg>
            )

        case 'chevron-right':
            return (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
                    <path d="M79.093 0L48.907 30.187 146.72 128l-97.813 97.813L79.093 256l128-128z"></path>
                </svg>
            )

        case 'delete':
            return (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.88 22.88">
                    <path d="M.324 1.909a1.14 1.14 0 010-1.587 1.14 1.14 0 011.587 0l9.523 9.539L20.973.322a1.12 1.12 0 011.571 0 1.112 1.112 0 010 1.587l-9.523 9.524 9.523 9.539a1.112 1.112 0 010 1.587 1.12 1.12 0 01-1.571 0l-9.539-9.539-9.523 9.539a1.14 1.14 0 01-1.587 0c-.429-.444-.429-1.159 0-1.587l9.523-9.539L.324 1.909z"></path>
                </svg>
            )

        case 'sound':
            return (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 485 485">
                    <path d="M282.5 55.15c-33.5 0-66.3 8.9-95 25.8-25.6 15.1-47.3 36.1-63.2 61.1H52.7c-29.1 0-52.7 23.6-52.7 52.7v95.5c0 29.1 23.6 52.7 52.7 52.7h71.6c15.9 25 37.6 46 63.2 61.1 28.7 16.9 61.5 25.8 95 25.8 7.5 0 13.5-6 13.5-13.5V68.65c0-7.4-6-13.5-13.5-13.5zm-164.1 260.8H52.7c-14.2 0-25.7-11.5-25.7-25.7v-95.5c0-14.2 11.5-25.7 25.7-25.7h65.7v146.9zm150.6 86.4c-50.7-4.3-96.9-32.8-123.6-76.6v-166.4c26.7-43.8 72.9-72.3 123.6-76.6v319.6zm79.9-264.4c-6.1-4.3-14.5-2.8-18.8 3.4-4.3 6.1-2.8 14.5 3.4 18.8 27 18.8 43.1 49.7 43.1 82.5s-16.1 63.7-43.1 82.5c-6.1 4.3-7.6 12.7-3.4 18.8 2.6 3.8 6.8 5.8 11.1 5.8 2.7 0 5.4-.8 7.7-2.4 34.2-23.9 54.7-63 54.7-104.6s-20.5-81-54.7-104.8z"></path>
                    <path d="M401.1 82.05c-6.1-4.3-14.5-2.8-18.8 3.4-4.3 6.1-2.8 14.5 3.4 18.8 45.3 31.6 72.3 83.3 72.3 138.3s-27 106.8-72.3 138.3c-6.1 4.3-7.6 12.7-3.4 18.8 2.6 3.8 6.8 5.8 11.1 5.8 2.7 0 5.4-.8 7.7-2.4 52.5-36.6 83.9-96.6 83.9-160.5s-31.3-123.9-83.9-160.5z"></path>
                </svg>
            )

        default:
            return ''
    }
}

export default Icon