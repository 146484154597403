import React from "react";

const Button = ({ as = 'button', isFull, isBig, children, ...args }) => {
    const Tag = as
    const classNames = ['button']
    const classNamesList = {
        big: isBig,
        full: isFull
    }

    Object.entries(classNamesList).forEach(([value, state]) => {
        state && classNames.push(`button--${value}`)
    })

    return (
        <Tag className={classNames.join(' ')} {...args}>
            {children}
        </Tag>
    )
}

export default Button