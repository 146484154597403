import React from "react";

const Figure = ({ image, title = '', ...args }) => {
    return (
        <figure {...args}>
            {image && (<img src={image} alt={title} />)}
        </figure>
    )
}

export default Figure