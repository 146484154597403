import React from "react";
import EmblaCarouselReact from 'embla-carousel-react'

const Carousel = ({ images, type, onClickSlide, showCaption, ...args }) => {
    const defaultsOptions = {
        align: 'start',
        slidesToScroll: 1,
        startIndex: 0,
        containScroll: true,
        loop: true
    }

    return (
        <EmblaCarouselReact {...{ options: defaultsOptions, ...args }}>
            <div className={`story-card__gallery-${type}`}>
                {images.map(({ image, image_caption }, key) => (
                    <div
                        key={key}
                        style={{ backgroundImage: `url(${image})` }}
                        onClick={e => onClickSlide && onClickSlide(e, key)}
                    >
                        {showCaption && image_caption && (
                            <div className="story-card__gallery-caption"><span>{image_caption}</span></div>
                        )}
                    </div>
                ))}
            </div>
        </EmblaCarouselReact >
    )
}

export default Carousel