import React from "react";

import { Figure } from '../General';

const Footer = () => {
    return (
        <footer className="main-footer">
            <div className="main-footer__sponsors">
                <ul>
                    <li>
                        <Figure image="http://unsplash.it/120/90" title="sponsor 1" />
                    </li>
                    <li>
                        <Figure image="http://unsplash.it/120/90" title="sponsor 2" />
                    </li>
                    <li>
                        <Figure image="http://unsplash.it/120/90" title="sponsor 3" />
                    </li>
                </ul>
            </div>

            <div className="main-footer__mark">
                <span>Designed and built by</span>
                <Figure image="http://unsplash.it/120/90" title="mark" />
            </div>
        </footer>
    )
}

export default Footer