import React, { useContext } from "react";

import { SearchStore } from '../../Stores'

import Story from './Story';
import Search from './Search';

const SearchStories = ({ onSearch, stories }) => {
    const { state: { activeStory } } = useContext(SearchStore)

    return (
        <div className="search-stories">
            {!!activeStory.id && (<Story data={activeStory} />)}
            <Search stories={stories} onSearch={onSearch} />
        </div>
    )
}

export default SearchStories