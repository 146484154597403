import React, { useContext } from "react";

import { SearchStore } from '../../../Stores';
import { makeActiveStore } from '../actions';

import { Button, Icon } from '../../General';

const Meta = ({ year, location }) => {
    const { dispatch } = useContext(SearchStore);

    const resetActive = e => {
        e.preventDefault()

        dispatch({
            type: 'ACTIVE_STORY',
            payload: {}
        })

        makeActiveStore({}, dispatch)
    }

    const Item = ({ label, value }) => {
        return (
            <li>
                <span>{label}</span>
                <h4>{value}</h4>
            </li>
        )
    }

    return (
        <aside className="story-meta">
            <div className="story-meta__content">
                <ul>
                    <Item label="Date" value={year} />
                    <Item label="Location" value={location} />
                </ul>

                <Button as="a" onClick={resetActive}>
                    <Icon name="arrow-left" />Back to Globe
                </Button>
            </div>
        </aside>
    )
}

export default Meta