import React from "react";

import { GlobalStoreProvider } from './Stores'

import Main from './components/Main';
import Footer from './components/Footer';

function App() {

    return (
        <div className="main-warp">
            <GlobalStoreProvider>
                <Main />
            </GlobalStoreProvider>

            <Footer />
        </div>
    )
}

export default App;
