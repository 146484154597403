import React from "react";

import Form from './Form';
import Result from './Result';

const Search = ({ onSearch, stories }) => {
    return (
        <aside className="search-sidebar">
            <Form
                title="Search the globe"
                buttonLabel="Search"
                onSearch={onSearch}
            />
            <Result stories={stories} />
        </aside>
    )
}

export default Search