import { useEffect, useRef, useCallback } from "react";

// import { mockResults } from '../../Stores/mock'
export const usePrevious = value => {
    const ref = useRef()

    useEffect(() => {
        ref.current = value;
    }, [value]);

    return ref.current;
}

export const useSleep = (isStandby, dispatch) => {
    useEffect(() => {
        let timeoutId = null

        const onAction = () => {
            clearTimeout(timeoutId)

            timeoutId = setTimeout(() => {
                dispatch({
                    type: 'ENTER_STANDBY'
                })
            }, 10000)
        }

        if (!isStandby) {
            onAction()
            document.addEventListener('mousemove', onAction)
            document.addEventListener('mouseup', onAction)
            document.addEventListener('wheel', onAction)
            document.addEventListener('touchend', onAction)
        }

        return () => {
            document.removeEventListener('mousemove', onAction)
            document.removeEventListener('mouseup', onAction)
            document.removeEventListener('wheel', onAction)
            document.removeEventListener('touchend', onAction)
        }
    }, [isStandby, dispatch])
}

export const useFetch = (url, dispatch, stories) => {
    const fetchData = useCallback(async () => {
        const getData = await fetch(url)

        getData
            .json()
            .then(({ list = [] }) => {
                dispatch({
                    type: 'FETCH_STORIES',
                    payload: list.map(item => {
                        const { latitude, longitude } = item
                        item.coordinates = [latitude, longitude]
                        item.value = 10

                        delete item.latitude
                        delete item.longitude

                        return item
                    })
                })
            })
    }, [dispatch, url])

    !stories.length && fetchData()
}