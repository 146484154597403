import React, { useContext } from "react";

import { SearchStore } from '../../../Stores';

import { Figure } from '../../General';

const StroyPreview = ({ story }) => {
    const { state: { activeStory = {} }, dispatch } = useContext(SearchStore);
    const { id, title, location, date, images = [] } = story
    const isActive = activeStory.id === id

    const makeActive = () => {
        dispatch({
            type: 'ACTIVE_STORY',
            payload: story
        })
    }

    return (
        <li>
            <div className={`search-results__item ${isActive ? 'active' : ''}`} onClick={makeActive}>
                <Figure image={(images[0] || {}).image} title={title} />

                <div className="search-results__item-content">
                    <div>
                        <span>{date}</span>
                        <span>{location}</span>
                    </div>

                    <h3>{title}</h3>
                </div>
            </div>
        </li>
    )
}

export default StroyPreview