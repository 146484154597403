import React, { useState, useEffect, useCallback, useContext } from "react";
import ReactGlobe, { tween, coordinatesToPosition } from "react-globe";

import { SearchStore } from '../../Stores'

import { cameraOptions, markerOptions, globeOptions } from './config'

const Globe = ({ stories, isStandby }) => {
    const { state: { activeStory }, dispatch } = useContext(SearchStore)

    const { coordinates: storyCoord } = activeStory
    const [globe, setGlobe] = useState({})
    const [width, setWidth] = useState(window.innerWidth)
    const [height, setHeight] = useState(window.innerHeight - 60)

    const markers = storyCoord ? [activeStory] : stories

    const updateSize = useCallback(() => {
        if (globe.renderer) {
            const { domElement: { width, height } } = globe.renderer

            setWidth(width)
            setHeight(height)
        }
    }, [globe])

    useEffect(() => {
        //window.glb = globe

        globe.updateFocus = () => { }
        updateSize()
    }, [globe, updateSize])

    useEffect(() => {
        window.addEventListener('resize', updateSize);

        return () => {
            window.removeEventListener('resize', updateSize);
        };
    }, [updateSize]);

    useEffect(useCallback(() => {
        const { camera } = globe

        if (camera) {
            const { x: scaleX, y: scaleY, z: scaleZ } = camera.scale
            const { offsetX = 0, offsetY = 0 } = camera.view || {}

            const updateGlobePosition = (offsetX = 0, offsetY = 0) => {
                camera.setViewOffset(width, height, offsetX, offsetY, width, height)
            }

            const updateCamera = (position, scale, offset) => {
                camera.position.set(...position)
                camera.scale.set(...scale)
                updateGlobePosition(...offset)
            }

            const getCoordinates = coordinates => (
                coordinatesToPosition(coordinates, 300 * globe.options.camera.distanceRadiusScale)
            )

            const animateCamera = (to, duration, onComplete = undefined) => {
                const from = {
                    z: scaleZ,
                    offset: [
                        offsetX,
                        offsetY
                    ],
                    position: Object.values(camera.position)
                }

                to = isStandby ? {
                    z: 180 / height,
                    offset: [
                        0,
                        height / 2 - 100,
                    ],
                    position: to.position
                } : to

                tween(from, to, duration, ['Cubic', 'InOut'], ({ z, offset, position }) => {
                    updateCamera(position, [scaleX, scaleY, z], offset)
                }, onComplete)
            }

            if (storyCoord) {
                globe.enableOrbitControls(false)

                animateCamera({
                    z: width / height * 0.1,
                    offset: [
                        width / 2 - 120,
                        height / 2 - 120,
                    ],
                    position: getCoordinates(storyCoord)
                }, 500, () => {
                    if (globe.markerObjects.children.length) {
                        tween({ scale: 1 }, { scale: 5 }, 300, ['Linear', 'None'], ({ scale }) => {
                            globe.markerObjects.children.length
                                && globe.markerObjects.children[0].scale.set(scale, scale, scale)
                        })
                    }
                })

            } else {
                globe.markerObjects.children.forEach(marker => marker.scale.set(1, 1, 1))
                globe.enableOrbitControls(true)

                animateCamera({
                    z: 1,
                    offset: [
                        220,
                        0
                    ],
                    position: getCoordinates([30, 30])
                }, scaleZ === 1 ? 0 : 500)
            }
        }
    }, [width, height, globe, storyCoord, isStandby]), [storyCoord, globe, height, height, isStandby])

    useEffect(useCallback(() => {
        if (isStandby && storyCoord) {
            dispatch({
                type: 'ACTIVE_STORY',
                payload: {}
            })
        }
    }, [dispatch, storyCoord, isStandby]), [isStandby])

    const onClickMarker = story => {
        dispatch({
            type: 'ACTIVE_STORY',
            payload: story
        })
    }

    const style = {
        height: '100%',
        background: !isStandby && storyCoord ? '#222' : ''
    }

    if (isStandby && !storyCoord) {
        style.opacity = 0
    }

    return (
        <div className="globe-warp" style={style}>
            <ReactGlobe
                markers={markers}
                cameraOptions={cameraOptions}
                markerOptions={markerOptions}
                globeOptions={globeOptions}
                onGetGlobeInstance={setGlobe}
                onClickMarker={onClickMarker}
            />
        </div>
    )
}

export default Globe