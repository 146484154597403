import React, { useState, useContext, useEffect } from "react";

import { GlobalStore, SearchStoreProvider } from '../../Stores';

import Globe from '../Globe';
import SearchStories from '../SearchStories';
//import Standby from '../Standby';
import Loader from '../Loader';

import { useSleep, useFetch } from '../Hooks'

const Main = () => {
    const { state: { isStandby, stories }, dispatch } = useContext(GlobalStore)
    const [localStories, setLocalStories] = useState(stories)
    let searchStore = {}

    //useSleep(isStandby, dispatch)
    useFetch('https://api.gahtc.org/api/stories/', dispatch, stories)

    const onSearch = searchTerm => {
        if (searchTerm && searchTerm.length > 2) {
            setLocalStories(stories.filter(({ title, description, location, year }) => (
                [title, description, location, year].some(el => (new RegExp(searchTerm, 'i')).test(el))
            )))
        } else {
            setLocalStories(stories)
        }
    }

    const onStandByExit = () => {
        const { dispatch: searchDispatch } = searchStore

        if (searchDispatch) {
            searchDispatch({
                type: 'ACTIVE_STORY',
                payload: {}
            })
        }

        return dispatch({
            type: 'EXIT_STANDBY'
        })
    }

    const onReadyStandbySlide = story => {
        const { dispatch } = searchStore

        if (dispatch) {
            dispatch({
                type: 'ACTIVE_STORY',
                payload: story
            })
        }
    }

    const getSearchStore = store => {
        searchStore = store
    }

    useEffect(() => {
        setLocalStories(stories)
    }, [stories])

    return (
        <>
            {!stories.length && (
                <Loader />
            )}

            <main className="main-content">
                <SearchStoreProvider getValue={getSearchStore}>
                    <Globe
                        stories={localStories}
                        isStandby={isStandby}
                    />

                    {!isStandby && (
                        <SearchStories
                            stories={localStories}
                            onSearch={onSearch}
                        />
                    )}
                </SearchStoreProvider>

                {/* {isStandby && (
                    <Standby
                        stories={stories}
                        onExit={onStandByExit}
                        onReady={onReadyStandbySlide}
                    />
                )} */}
            </main>

        </>
    )
}

export default Main