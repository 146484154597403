import React, { useState, useContext } from "react";

import { SearchStore } from '../../../Stores';
import { Button, Icon } from '../../General';

const Form = ({ title, buttonLabel, onSearch }) => {
    const { state: { activeStory }, dispatch } = useContext(SearchStore);
    const [searchTerm, setSearchTerm] = useState('')

    const onSubmit = e => {
        e.preventDefault();

        if (activeStory.id) {
            dispatch({
                type: 'ACTIVE_STORY',
                payload: {}
            })
        }

        onSearch(searchTerm)
    }

    const updateSearchTerm = e => {
        setSearchTerm(e.target.value);
    }

    const clearSearch = () => {
        setSearchTerm('')
        onSearch('')
    }

    return (
        <div className="search-form">
            <form onSubmit={onSubmit}>
                <div>
                    <label>
                        <span>{title}</span>
                        <input type="text" autoComplete="off" value={searchTerm} onChange={updateSearchTerm} />
                    </label>

                    {searchTerm && (
                        <div className="search-form__clear" onClick={clearSearch}>
                            <Icon name="delete" />
                        </div>
                    )}
                </div>
                <div>
                    <Button type="submit" isFull>{buttonLabel}</Button>
                </div>
            </form>
        </div>
    )
}

export default Form