import * as THREE from "three";

export const cameraOptions = {
    autoRotateSpeed: 0.1,
    enableAutoRotate: true,
    enableZoom: false
};

export const globeOptions = {
    enableBackground: false,
    glowRadiusScale: 0.1
};

export const markerOptions = {
    enableTooltip: false,
    renderer: marker => {
        const color = "#ea4c4d";

        const creteRing = (ringSettings = [], opacity = 1.0) => {
            const ringGeo = new THREE.RingGeometry(...ringSettings);
            const ringMat = new THREE.MeshBasicMaterial({
                color: color,
                side: THREE.DoubleSide,
                transparent: true,
                opacity
            });

            return new THREE.Mesh(ringGeo, ringMat);
        }

        const center = new THREE.Mesh(
            new THREE.CircleGeometry(2, 32),
            new THREE.MeshBasicMaterial({
                color,
                side: THREE.DoubleSide,
                transparent: true,
                opacity: 1.0
            })
        );

        const firstRingMesh = creteRing([2, 5, 32], 0.25);
        const secondRingMesh = creteRing([5, 5.5, 32]);

        return new THREE.Object3D().add(center, firstRingMesh, secondRingMesh);
    }
};