import React from "react";

import Gallery from './Gallery';
import Meta from './Meta';

const Story = ({ data: { year, location, abstract, title, images = [] } }) => {
    return (
        <div className="story-content">
            <Meta year={year} location={location} />

            <div className="story-card">
                <h1>{title}</h1>

                {!!images.length && (
                    <Gallery images={images} />
                )}

                <div
                    className="story-card__content"
                    dangerouslySetInnerHTML={{ __html: abstract }}
                >
                </div>
            </div>
        </div>
    )
}

export default Story