import React from "react";

import StoryPreview from './StoryPreview';

const Result = ({ stories = [] }) => {
    return (
        <div className="search-results">
            {!!stories.length && (
                <ul>
                    {stories.map(story => (
                        <StoryPreview key={story.id} story={story} />
                    ))}
                </ul>
            )}
        </div>
    )
}

export default Result