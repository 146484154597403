import React, { useState, useEffect } from "react";

import Carousel from './Carousel';
import { Icon } from '../../General';

const Gallery = ({ images }) => {
    const [previewCarousel, setPreviewCarousel] = useState(null)
    const [thumbCarousel, setThumbCarousel] = useState(null)

    useEffect(() => {
        if (thumbCarousel && previewCarousel) {
            thumbCarousel.on('select', () => {
                const activeIndex = thumbCarousel.selectedScrollSnap()

                previewCarousel.scrollTo(activeIndex)
            })

            previewCarousel.on('dragEnd', () => {
                const activeIndex = previewCarousel.selectedScrollSnap()

                thumbCarousel.scrollTo(activeIndex)
            })
        }

    }, [previewCarousel, thumbCarousel])

    const GalleryControl = ({ type, icon }) => {
        const onClick = e => {
            e.preventDefault()

            if (!thumbCarousel) {
                return ''
            }

            if (type === 'prev') {
                thumbCarousel.scrollPrev()
            } else {
                thumbCarousel.scrollNext()
            }
        }

        return (
            images.length > 4 ? (
                <div onClick={onClick} className={`story-card__gallery-${type}`}>
                    <Icon name={icon} />
                </div>
            ) : ''
        )
    }

    const onClickSlide = (e, index) => {
        e.preventDefault()

        if (thumbCarousel) {
            thumbCarousel.scrollTo(index)
        }
    }

    return (
        <div className="story-card__gallery">
            <Carousel
                emblaRef={setPreviewCarousel}
                images={images}
                type="preview"
                showCaption
            />

            {images.length > 1 && (
                <div className="story-card__gallery-nav">
                    <GalleryControl type="prev" icon="chevron-left" />

                    <div className="story-card__gallery-mini">
                        <Carousel
                            type="thumb"
                            images={images}
                            emblaRef={setThumbCarousel}
                            onClickSlide={onClickSlide}
                            options={{ align: 'center' }}
                        />
                    </div>

                    <GalleryControl type="next" icon="chevron-right" />
                </div>
            )}
        </div>
    )
}

export default Gallery